<template>
    <div>
        <div v-if="!pageIndexApiDataIsLoading">

            <company-licence-component/>

            <div class="row">
                <div class="col-md-12 m-b-0">
                    <router-link class="btn btn-white m-r-5" to="/admin"><i class="fa fa-cogs fa-fw fa-lg"></i></router-link>
                    <router-link class="btn btn-white m-r-5" to="/admin/products"><i class="fa fa-cubes fa-fw fa-lg"></i> Gestion des Articles</router-link>
                    <router-link class="btn btn-white m-r-5" to="/admin/clients"><i class="fa fa-users fa-fw fa-lg"></i> Gestion des Clients</router-link>
                    <router-link class="btn btn-white m-r-5 text-orange" to="/admin/exports"><i class="fa fa-file-download fa-fw fa-lg text-orange"></i> Export des Données</router-link>
                </div>
            </div>

            <!--<h1 class="page-header">Exports CSV<small class="lead"> Exportez vos données vers un ERP.</small></h1>-->
            <hr class="bg-grey-lighter"/>

            <div class="row m-b-5">
                <div class="col-md-12 m-b-0">
                    <router-link class="btn btn-white m-r-5" to="/admin/exports/excel"><i class="fa fa-file-excel fa-fw fa-lg"></i> Excel</router-link>
                    <router-link class="btn btn-white m-r-5 text-orange" to="/admin/exports/csv"><i class="fa fa-file fa-fw fa-lg text-orange"></i> CSV</router-link>
                </div>
            </div>

            <div v-if="pageIndexApiData != null">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="panel">
                            <div class="panel-body">
                                <div >
                                    <h2 v-if="pusherPayload.status == 'loading'"><i class="fas fa-spinner fa-pulse"></i> Chargement en cours ...</h2>
                                    <h2 v-else>Export au format CSV</h2>
                                    <span v-if="documentDownloadUrlApiDataErrorMessage != null">
                                        <small class="lead text-danger">{{documentDownloadUrlApiDataErrorMessage}}</small><br/>
                                    </span>
                                    <span v-if="documentDownloadUrlApiDataErrorMessage == null && pusherPayload.message != ''">
                                        <small class="lead"  :class="{'text-success':pusherPayload.status == 'success','text-warning':pusherPayload.status == 'warning','text-danger':pusherPayload.status == 'error',}">{{pusherPayload.message}}</small><br/>
                                    </span>
                                    <br/>
                                    <div v-if="pusherPayload.download_url">
                                        <a class="btn btn-xl btn-danger" :href="pusherPayload.download_url" target="_blank"><i class="fa fa-download fa-fw fa-lg"></i> Telecharger</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h2>Ventes &amp; Encaissements <small></small></h2>
                <div class="row">
                    <div class="col-3">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4>Ventes</h4>
                            </div>
                            <div class="panel-body">
                                <div>
                                    <form @submit.prevent="getSalesRecordsDownloadUrl">

                                        <div class="form-group row row-space-10">
                                          <div class="col-12">
                                            <label>Définir la période</label>
                                            <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                                               opens="right"
                                                               :ranges="dateRanges"
                                                               :locale-data="{
                                                                format: 'dd-mm-yyyy',
                                                                separator: ' - ',
                                                                applyLabel: 'Appliquer',
                                                                cancelLabel: 'Annuler',
                                                                weekLabel: 'S',
                                                                customRangeLabel: 'Plage Perso.',
                                                                daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                                monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                                firstDay: 1
                                                            }"
                                                               :singleDatePicker="false"
                                                               :timePicker="false"
                                                               :timePicker24Hour="true"
                                                               :showWeekNumbers="false"
                                                               :showDropdowns="false"
                                                               :autoApply="true"
                                                               v-model="salesDownloadFormData.date_range"
                                                               @update="()=>{}"
                                                               :linkedCalendars="false">
                                              <template v-slot:input="picker" class="text-right">
                                                {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                                                <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                                              </template>
                                            </date-range-picker>
                                          </div>
                                        </div>

                                        <div class="form-group row row-space-10">
                                            <label class="col-label">Site Financier</label>
                                            <div class="col-12">
                                                <v-select label="name" :options="pageIndexApiData.inventory_codes"
                                                          :reduce="item => item.code"
                                                          v-model="salesDownloadFormData.inventory_codes" multiple/>
                                            </div>
                                        </div>

                                        <div class="form-group row row-space-10">
                                            <label class="col-label">Point de vente</label>
                                            <div class="col-12">
                                                <v-select label="name" :options="pageIndexApiData.warehouses"
                                                          :reduce="item => item.id"
                                                          v-model="salesDownloadFormData.warehouse_ids" multiple/>
                                            </div>
                                        </div>

                                        <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'" type="submit"
                                                class="btn btn-block btn-success">Exporter
                                        </button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4>Règlements</h4>
                            </div>
                            <div class="panel-body">
                                <div>
                                    <form @submit.prevent="getPaymentRecordsDownloadUrl">

                                        <div class="form-group row row-space-10">
                                          <div class="col-12">
                                            <label>Définir la période</label>
                                            <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                                               opens="right"
                                                               :ranges="dateRanges"
                                                               :locale-data="{
                                                                format: 'dd-mm-yyyy',
                                                                separator: ' - ',
                                                                applyLabel: 'Appliquer',
                                                                cancelLabel: 'Annuler',
                                                                weekLabel: 'S',
                                                                customRangeLabel: 'Plage Perso.',
                                                                daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                                monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                                firstDay: 1
                                                            }"
                                                               :singleDatePicker="false"
                                                               :timePicker="false"
                                                               :timePicker24Hour="true"
                                                               :showWeekNumbers="false"
                                                               :showDropdowns="false"
                                                               :autoApply="true"
                                                               v-model="paymentDownloadFormData.date_range"
                                                               @update="()=>{}"
                                                               :linkedCalendars="false">
                                              <template v-slot:input="picker" class="text-right">
                                                {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                                                <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                                              </template>
                                            </date-range-picker>
                                          </div>
                                        </div>


                                        <div class="form-group row row-space-10">
                                            <label class="col-label">Site Financier</label>
                                            <div class="col-12">
                                                <v-select label="name" :options="pageIndexApiData.inventory_codes"
                                                          :reduce="item => item.code"
                                                          v-model="paymentDownloadFormData.inventory_codes" multiple/>
                                            </div>
                                        </div>

                                        <div class="form-group row row-space-10">
                                            <label class="col-label">Point de vente</label>
                                            <div class="col-12">
                                                <v-select label="name" :options="pageIndexApiData.warehouses"
                                                          :reduce="item => item.id"
                                                          v-model="paymentDownloadFormData.warehouse_ids" multiple/>
                                            </div>
                                        </div>

                                        <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'" type="submit"
                                                class="btn btn-block btn-success">Exporter
                                        </button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-loader" v-else><span class="spinner-small"></span></div>
    </div>
</template>


<script>
    import axios from 'axios'
    import pageApi from '../../store/api/admin/csv-exports-page-api'
    import LocalStorage from "../../store/local-storage";
    import CompanyLicenceComponent from '../../components/app/base/CompanyLicenceComponent.vue'


    export default {
        name: 'csv-exports-page-component',
        components: {
            CompanyLicenceComponent,
        },
        props: {},
        watch: {},
        computed: {},
        methods: {
            //Initialization Methods
            loadPageScripts() {
            },

            listenToServerBroadcastChannel() {
                let user = LocalStorage.getUserInfo();
                if (user.id != null && user.company_id != null) {
                    window.Echo.channel(window.appConfig.pusherBeamsChannelPrefix + '-document-download-ready-private-broadcast-channel-' + user.company_id + '-' + user.id)
                        .listen('DocumentDownloadLinkReadyPrivateEvent', (e) => {
                            this.pusherPayload = e.pusher_payload;
                        });
                }
            },

          formatDateInput (value) {
            if (value) {
              return window.moment(String(value)).format(window.appDefaultDisplayDateTimeFormat)
            }
            return null;
          },

            //Other Methods
            async getPageIndexApiData() {
                this.pageIndexApiDataIsLoading = true;
                this.pageIndexApiDataErrorMessage = null;
                try {
                    const response = await pageApi.getIndexData();
                    //console.log(response);
                    this.pageIndexApiData = response.data;
                    this.pageIndexApiDataIsLoading = false;
                    this.pageIndexApiDataErrorMessage = null;
                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
                            this.pageIndexApiDataIsLoading = false;
                            this.pageIndexApiDataErrorMessage = error.response.data.message;
                            LocalStorage.clearSessionData();
                            this.$router.push('login');
                        } else {
                            this.pageIndexApiDataIsLoading = false;
                            this.pageIndexApiDataErrorMessage = error.response.data.message;
                            //
                            this.$swal({
                                icon: 'error',//'info','success','warning','error','question',
                                toast: true,
                                position: 'bottom-end',
                                timer: 3000,
                                showConfirmButton: false,
                                title: 'HTTP ' + error.response.status,
                                text: error.response.data.message,
                            })
                        }
                    } else {
                        this.pageIndexApiDataIsLoading = false;
                        this.pageIndexApiDataErrorMessage = error.message;
                        //
                        this.$swal({
                            icon: 'error',//'info','success','warning','error','question',
                            toast: true,
                            position: 'bottom-end',
                            timer: 3000,
                            showConfirmButton: false,
                            //title: error.message
                            text: error.message,
                        })
                    }
                }
            },

            async getSalesRecordsDownloadUrl() {

                this.pusherPayload.download_url = null;

                this.documentDownloadUrlApiDataIsLoading = true;
                this.documentDownloadUrlApiDataErrorMessage = null;
                this.documentDownloadUrlApiDataValidationErrors = [];
                try {
                    const response = await pageApi.getSalesRecordsDownloadUrl({
                      date_from: this.formatDateInput(this.salesDownloadFormData.date_range.startDate),
                      date_to: this.formatDateInput(this.salesDownloadFormData.date_range.endDate),
                      inventory_codes: this.salesDownloadFormData.inventory_codes,
                      warehouse_ids: this.salesDownloadFormData.warehouse_ids,
                      email: this.salesDownloadFormData.email
                    });
                    //console.log(response);
                    this.documentDownloadUrlApiData = response.data;
                    this.documentDownloadUrlApiDataIsLoading = false;
                    this.documentDownloadUrlApiDataErrorMessage = null;
                    this.documentDownloadUrlApiDataValidationErrors = [];
                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        this.documentDownloadUrlApiDataIsLoading = false;
                        this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
                        if (error.response.status === 422) {
                            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
                        } else {
                            this.documentDownloadUrlApiDataValidationErrors = [];
                        }
                    } else {
                        this.documentDownloadUrlApiDataIsLoading = false;
                        this.documentDownloadUrlApiDataErrorMessage = error.message;
                        this.documentDownloadUrlApiDataValidationErrors = [];
                    }
                }
            },

            async getPaymentRecordsDownloadUrl() {

                this.pusherPayload.download_url = null;

                this.documentDownloadUrlApiDataIsLoading = true;
                this.documentDownloadUrlApiDataErrorMessage = null;
                this.documentDownloadUrlApiDataValidationErrors = [];
                try {
                    const response = await pageApi.getPaymentRecordsDownloadUrl({
                      date_from: this.formatDateInput(this.paymentDownloadFormData.date_range.startDate),
                      date_to: this.formatDateInput(this.paymentDownloadFormData.date_range.endDate),
                      inventory_codes: this.paymentDownloadFormData.inventory_codes,
                      warehouse_ids: this.paymentDownloadFormData.warehouse_ids,
                      email: this.paymentDownloadFormData.email
                    });
                    //console.log(response);
                    this.documentDownloadUrlApiData = response.data;
                    this.documentDownloadUrlApiDataIsLoading = false;
                    this.documentDownloadUrlApiDataErrorMessage = null;
                    this.documentDownloadUrlApiDataValidationErrors = [];
                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        this.documentDownloadUrlApiDataIsLoading = false;
                        this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
                        if (error.response.status === 422) {
                            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
                        } else {
                            this.documentDownloadUrlApiDataValidationErrors = [];
                        }
                    } else {
                        this.documentDownloadUrlApiDataIsLoading = false;
                        this.documentDownloadUrlApiDataErrorMessage = error.message;
                        this.documentDownloadUrlApiDataValidationErrors = [];
                    }
                }
            },

            //
            downloadDocumentFromUrl(url, fileName) {
                axios.get(url, {responseType: 'blob'})
                    .then(response => {
                        //console.log(response.data);

                        let file = new Blob([response.data]);
                        let fileURL = window.URL.createObjectURL(file);

                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', fileName);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
                    .catch(error => {
                        //console.log(error.response.data.message);
                        this.$swal({
                            type: 'error',//'info','success','warning','error','question',
                            toast: true,
                            position: 'bottom-end',
                            timer: 3000,
                            showConfirmButton: false,
                            title: error.response.data.message
                            //text: error.response.data.message,
                        })
                    });
            },
        },
        data: function () {

          let today = new Date()
          today.setHours(0, 0, 0, 0)
          let todayEnd = new Date()
          todayEnd.setHours(11, 59, 59, 999);
          let yesterdayStart = new Date()
          yesterdayStart.setDate(today.getDate() - 1)
          yesterdayStart.setHours(0, 0, 0, 0);
          let yesterdayEnd = new Date()
          yesterdayEnd.setDate(today.getDate() - 1)
          yesterdayEnd.setHours(11, 59, 59, 999);
          let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
          let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);


          return {
                appName: window.appConfig.appName,
                appCurrency: window.appConfig.appCurrency,
                intervalId01: 0,

                pusherPayload: {
                    status: '',
                    message: '',
                    download_url: null
                },

                salesDownloadFormData: {
                  date_range: {
                    startDate: null,
                    endDate: null,
                  },
                    inventory_codes: [],
                    warehouse_ids: [],
                    email: ''
                },

                paymentDownloadFormData: {
                  date_range: {
                    startDate: null,
                    endDate: null,
                  },
                    inventory_codes: [],
                    warehouse_ids: [],
                    email: ''
                },

            dateRanges: {
              "Aujourd'hui": [today, todayEnd],
              "Hier": [yesterdayStart, yesterdayEnd],
              "Mois en cours": [thisMonthStart, thisMonthEnd],
              "Mois dernier": [
                new Date(today.getFullYear(), today.getMonth() - 1, 1),
                new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)
              ],
              "Année en cours": [
                new Date(today.getFullYear(), 0, 1),
                new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)
              ],
              "Année dernière": [
                new Date(today.getFullYear() - 1, 0, 1),
                new Date(today.getFullYear()- 1, 11, 31, 11, 59, 59, 999)
              ],
            },

                //API Data
                //
                pageIndexApiData: null,
                pageIndexApiDataIsLoading: false,
                pageIndexApiDataErrorMessage: null,

                //
                documentDownloadUrlApiData: null,
                documentDownloadUrlApiDataIsLoading: false,
                documentDownloadUrlApiDataErrorMessage: null,
                documentDownloadUrlApiDataValidationErrors: [],
            };
        },
        created: function () {
            this.getPageIndexApiData();
        },
        mounted: function () {
            this.loadPageScripts();
            this.listenToServerBroadcastChannel();
        },
        beforeDestroy: function () {
        },
    }
</script>
